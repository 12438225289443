.tab-link-tab-buttons{
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  span{
    text-transform: capitalize;
  }
}
.tab-link-color-indicator-container{
  position: absolute;
  top: 0;
  left: 0;

  height: 16px;
  width: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding-right: 4px;
}
.tab-link-color-indicator{
  width: 9px;
  height: 9px;
  border-radius: 50%;
}
