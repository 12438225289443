.vehicle-display-info-container{
  font-size: .85em;
}
.vehicle-display-info{
  display: grid;
  grid-template-columns: 4fr 5fr;
  padding: 4px 10px;
}
.vehicle-display-info-details{
  color:#4d4d4d;
  // border-right: 1px solid rgba(0,0,0,.2);

}
.vehicle-display-info-details-ymm{
  font-size: 1.25em;
  font-weight: bolder;
}
.vehicle-display-notes-items{
  margin-top: 2px;
  height: 100px;
  overflow-y: scroll;
  box-shadow: inset 0 0 2px rgba(0,0,0,0.2);
  padding-top: 10px;
  border-radius: 3px;
  position: relative;

  &:hover {
    overflow-y: visible;
    display: block;
    z-index: 700;
  }

  .card{
    width: 97.5%;
    margin: 0 auto;
  }
}
.vehicle-display-notes{
  font-size: 1.1em;
  .input-group .form-control{
    padding: 2px 5px;
  }
  .input-group{
    button{
      height:24px;
      padding: 0 6px;
      font-size: .85em;
    }
    input{
      height: 24px;
      line-height: 20px;
    }
  }
  .input-group-text{
    padding: 0 4px;
  }

}
.vehicle-display-info-details-vin-stock-copy{
  cursor: pointer;
  svg{

    transition: all ease-in-out 150ms;
  }
  &:hover{
    svg{
      transform: scale(1.07);
    }
  }
}
.vehicle-display-info-details-additional{

  .vehicle-display-info-details-additional-toggle{
    box-shadow: 0 0 1px 1px rgba(0,0,0,.2);
    cursor: pointer;
    transition: all ease-in-out 150ms;
    &:hover{
      background-color: rgb(235,235,235)
    }
  }
  .vehicle-display-info-details-additional-toggle-open{
    background-color: rgb(235,235,235)
  }
}


.vehicle-display-notes .input-group {
  position: relative;
  width: 100%;
}

.autocomplete-suggestions {
  position: absolute;
  z-index: 10000;
  width: 100%;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.autocomplete-suggestions .list-group-item {
  cursor: pointer;
}
