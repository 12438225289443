.dealership-link-container{
  width: 100%;
  border-bottom: 1px solid rgba(200,200,200,1);
  color: rgb(70,70,70);

  padding: 2px 10px;
  background: white;
  // border-radius: 5px;
  // box-shadow: 0 0 1px 1px rgba(0,0,0,.2);
  font-size: .85em;
  &:nth-of-type(even){
    background-color: lighten(#2C55FF, 38%);
  }
}
.dealership-link{
  display: grid;
  grid-template-columns: 5fr 2fr;
  height: 100%;
}

.dealership-link-dealer-name{
  font-size: 1.15em;
  text-transform: capitalize;
  color: #2c55ff;
  font-weight: bold;
}
.dealership-link-dealer-id{
  font-size: .75em;
  color: rgb(170,170,170);
}
.dealership-link-info{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.dealership-link-info-secondary{
  display: grid;
  grid-template-columns: 1fr 1fr;
  .dealership-link-info-secondary-item{
    display: flex;
    align-items: center;
  }
}
.dealership-link-buttons{
  display: flex;
  align-items: center;
  justify-content: flex-end;


  button{
    background-color: #2c55ff;
    color: white;
    border-radius: 3px;
    width: 150px;
    border:none;
    margin: 2px 2px;
    padding: 2px 0;
  }
}

@media only screen and (max-width: 900px) {
  .dealership-link-info{
    display: grid;
    grid-template-columns: 1fr;
  }
  .dealership-link-buttons{
    display: grid;
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 600px) {
  .dealership-link-container{
    font-size: .75em;
  }
  .dealership-link-buttons{
    button{
      width: 120px;
    }
  }
}
