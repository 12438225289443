a {
  color: #FFF;
}

a:hover {
    color: #FFF;
}

.side-box a {
  color: #000;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.95);
  overflow-x: hidden;
  transition: 0.5s;
}

.main-vehicles, .reportbox {
  height: 90vh;
}
.swatches-picker > div > div {
  background: transparent !important;
}

.text-muted{
  color: #98a6ad !important;
  font-stretch: condensed;
}

.border-dark {
    border-color: #333 !important;
}

.bg-white{
  background-color:#FFF;
  background:#FFF;
}

.bg-light-grey{
  background-color:#F1F1F1;
  background: linear-gradient(45deg, #EEEEEE, #FAF8F9, #FFFFFF);
}
.bg-dark-grey{
  background-color:#464956;
  background:#464956;
}
.bg-dark{
  background-color:#343a40!important
}
.btn-light{
  color:#666;
  background-color:#efefef;
  background:#efefef;
}

.bg-yellow{
  color:#000;
  background-color:rgb(255, 241, 1);
  background:rgb(255, 241, 1);
}

.bg-green{
  color:#FFF;
  background-color:rgb(56, 142, 60);
  background:rgb(56, 142, 60);
}

.bg-red{
  color:#FFF;
  background-color:rgb(183, 28, 28);
  background:rgb(183, 28, 28);
}

.bg-asteroid{
  background: #0F2027;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bg-mirage {
  background: #16222A;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #3A6073, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #3A6073, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.bg-success, .btn-success, .badge-success, .badge-primary, .btn-primary {
  background: #2C55FF;
  background-color: #2C55FF;
  color: #FFF;
}

.color-gold{
  color: #FFD700;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}

.color-purple{
  color: #800080;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}

.color-green{
  color: #228C22;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}

.color-pink{
  color: #e91e63;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}


.color-skyblue{
  color: #4dd0e1;
  text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}

.fa-star g g path {
  stroke: black;
  stroke-width: 10;
}

.badge {
  font-size: 95%;
}

.v80{
  height: 80vh;
}

.form-control {
  font-size: .9rem;
}

.input-group-addon.primary {
    color: rgb(255, 255, 255);
    background-color: rgb(50, 118, 177);
    border-color: rgb(40, 94, 142);
}
.input-group-addon.success {
    color: rgb(255, 255, 255);
    background: #2C55FF !important;
    background-color: #2C55FF !important;
    border-color: rgb(76, 174, 76);
}
.input-group-addon.info {
    color: rgb(255, 255, 255);
    background-color: rgb(57, 179, 215);
    border-color: rgb(38, 154, 188);
}
.input-group-addon.warning {
    color: rgb(255, 255, 255);
    background-color: rgb(240, 173, 78);
    border-color: rgb(238, 162, 54);
}
.input-group-addon.danger {
    color: rgb(255, 255, 255);
    background-color: rgb(217, 83, 79);
    border-color: rgb(212, 63, 58);
}


.input-group-append.primary {
    color: rgb(255, 255, 255);
    background-color: rgb(50, 118, 177);
    border-color: rgb(40, 94, 142);
}
.input-group-append.success {
    color: rgb(255, 255, 255);
    background: #2C55FF !important;
    background-color: #2C55FF !important;
    border-color: rgb(76, 174, 76);
}
.input-group-append.info {
    color: rgb(255, 255, 255);
    background-color: rgb(57, 179, 215);
    border-color: rgb(38, 154, 188);
}
.input-group-append.warning {
    color: rgb(255, 255, 255);
    background-color: rgb(240, 173, 78);
    border-color: rgb(238, 162, 54);
}
.input-group-append.danger {
    color: rgb(255, 255, 255);
    background-color: rgb(217, 83, 79);
    border-color: rgb(212, 63, 58);
}



.input-group-text.primary {
    color: rgb(255, 255, 255);
    background-color: rgb(50, 118, 177);
    border-color: rgb(40, 94, 142);
}
.input-group-text.success {
    color: rgb(255, 255, 255);
    background: #2C55FF !important;
    background-color: #2C55FF !important;
    border-color: rgb(76, 174, 76);
}
.input-group-text.info {
    color: rgb(255, 255, 255);
    background-color: rgb(57, 179, 215);
    border-color: rgb(38, 154, 188);
}
.input-group-text.warning {
    color: rgb(255, 255, 255);
    background-color: rgb(240, 173, 78);
    border-color: rgb(238, 162, 54);
}
.input-group-text.danger {
    color: rgb(255, 255, 255);
    background-color: rgb(217, 83, 79);
    border-color: rgb(212, 63, 58);
}

.btn-grey:hover, .btn-default:hover{
  color:#000;
  background-color:#FFF;
  background:#FFF;
}


.Select-placeholder, .Select--single > .Select-control .Select-value{
  height:24px;
  line-height:24px;
}

.Select-input, .Select-value, .Select-control{
  height:24px;
  line-height:24px;
}

.Select-control{
  border:none;
}

.select-page{
  display: block;
  width: 140px;
  padding: 0.1rem 0.1rem;
  font-size: .9rem;
  font-weight: 400;
  line-height: 1.0;
  color: #495057;
  background: inherit !important;
  border: none !important;
}

.input-group-text{
  padding:.4rem .75rem;
  border-radius:0;
  font-size: .7rem;
}

.input-group .form-control{
  padding:.2rem .75rem;
  font-size: .7rem;
}

.small-font{
  font-size: 80%;
}

.mobile-left-2{
  padding-left:1em;
}
.mobile-right-2{
  padding-right:1em;
}

.mobile-space-top-1{
  margin-top:1em;
}
.mobile-space-bottom-half{
  margin-bottom:.5em;
}
.mobile-space-top-half{
  margin-top:.5em;
}
.mobile-space-bottom-1{
  margin-bottom:1em;
}
.mobile-space-top-2{
  margin-top:2em;
}
.mobile-mobile-space-bottom-2{
  margin-bottom:2em;
}
.mobile-space-top-3 {
  margin-top:3em;
}
.mobile-space-bottom-3 {
  margin-bottom:3em;
}

.mobile-no-pad{
  padding-top:0;
  padding-bottom:0;
  padding-right:0;
  padding-left:0;

}

.mobile-pad-top-p1{
  padding-top:1px;
}
.mobile-pad-top-p2{
  padding-top:2px;
}

.mobile-pad-bottom-p1{
  padding-bottom:1px;
}
.mobile-pad-bottom-p2{
  padding-bottom:2px;
}

.mobile-pad-top-p3{
  padding-top:3px;
}
.mobile-pad-top-p5{
  padding-top:5px;
}


.mobile-pad-bottom-p3{
  padding-bottom:3px;
}
.mobile-pad-bottom-p5{
  padding-bottom:5px;
}


.mobile-pad-top-half{
  padding-top:.5em;
}
.mobile-pad-bottom-half{
  padding-bottom:.5em;
}


.mobile-pad-top-0{
  padding-top:0em;
}
.mobile-pad-bottom-0{
  padding-bottom:0em;
}

.mobile-pad-top-1{
  padding-top:1em;
}
.mobile-pad-bottom-1{
  padding-bottom:1em;
}
.mobile-pad-top-2{
  padding-top:2em;
}
.mobile-pad-bottom-2{
  padding-bottom:2em;
}
.mobile-pad-top-3 {
  padding-top:3em;
}
.mobile-pad-bottom-3 {
  padding-bottom:3em;
}

.mobile-space-left-0{
  margin-left:0em;
}
.mobile-space-right-0{
  margin-right:0em;
}
.mobile-space-left-half{
  margin-left:.5em;
}
.mobile-space-right-half{
  margin-right:.5em;
}
.mobile-space-left-1{
  margin-left:1em;
}
.mobile-space-right-1{
  margin-right:1em;
}
.mobile-space-left-2{
  margin-left:1em;
}
.mobile-space-right-2{
  margin-right:1em;
}
.mobile-space-left-3{
  margin-left:1em;
}
.mobile-space-right-3{
  margin-right:1em;
}



.mobile-pad-left-0{
  padding-left:0em;
}
.mobile-pad-right-0{
  padding-right:0em;
}
.mobile-pad-left-1{
  padding-left:1em;
}
.mobile-pad-right-1{
  padding-right:1em;
}
.mobile-pad-left-2{
  padding-left:1em;
}
.mobile-pad-right-2{
  padding-right:1em;
}
.mobile-pad-left-3{
  padding-left:1em;
}
.mobile-pad-right-3{
  padding-right:1em;
}


@media (min-width: 768px) {


  .space-top-1{
  margin-top:1em;
}
.space-bottom-half{
  margin-bottom:.5em;
}
.space-top-half{
  margin-top:.5em;
}
.space-bottom-1{
  margin-bottom:1em;
}
.space-top-2{
  margin-top:2em;
}
.space-bottom-2{
  margin-bottom:2em;
}
.space-top-3 {
  margin-top:3em;
}
.space-bottom-3 {
  margin-bottom:3em;
}

.no-pad{
  padding-top:0;
  padding-bottom:0;
  padding-right:0;
  padding-left:0;

}

.pad-top-p1{
  padding-top:1px;
}
.pad-top-p2{
  padding-top:2px;
}

.pad-bottom-p1{
  padding-bottom:1px;
}
.pad-bottom-p2{
  padding-bottom:2px;
}

.pad-top-p3{
  padding-top:3px;
}
.pad-top-p5{
  padding-top:5px;
}


.pad-bottom-p3{
  padding-bottom:3px;
}
.pad-bottom-p5{
  padding-bottom:5px;
}


.pad-top-half{
  padding-top:.5em;
}
.pad-bottom-half{
  padding-bottom:.5em;
}


.pad-top-0{
  padding-top:0em;
}
.pad-bottom-0{
  padding-bottom:0em;
}

.pad-top-1{
  padding-top:1em;
}
.pad-bottom-1{
  padding-bottom:1em;
}
.pad-top-2{
  padding-top:2em;
}
.pad-bottom-2{
  padding-bottom:2em;
}
.pad-top-3 {
  padding-top:3em;
}
.pad-bottom-3 {
  padding-bottom:3em;
}

.space-left-0{
  margin-left:0em;
}
.space-right-0{
  margin-right:0em;
}
.space-left-half{
  margin-left:.5em;
}
.space-right-half{
  margin-right:.5em;
}
.space-left-1{
  margin-left:1em;
}
.space-right-1{
  margin-right:1em;
}
.space-left-2{
  margin-left:1em;
}
.space-right-2{
  margin-right:1em;
}
.space-left-3{
  margin-left:1em;
}
.space-right-3{
  margin-right:1em;
}



.pad-left-0{
  padding-left:0em;
}
.pad-right-0{
  padding-right:0em;
}
.pad-left-1{
  padding-left:1em;
}
.pad-right-1{
  padding-right:1em;
}
.pad-left-2{
  padding-left:1em;
}
.pad-right-2{
  padding-right:1em;
}
.pad-left-3{
  padding-left:1em;
}
.pad-right-3{
  padding-right:1em;
}



  .desk-w-10{
    width: 10% !important;
  }
  .desk-w-15{
    width: 15% !important;
  }

  .desk-w-20{
    width: 20% !important;
  }
  .desk-w-25{
    width: 25% !important;
  }


  .desk-w-30{
    width: 30% !important;
  }

  .desk-w-33{
    width: 33% !important;
  }


  .desk-w-40{
    width: 40% !important;
  }
  .desk-w-50{
    width: 50% !important;
  }
  .desk-w-60{
    width: 60% !important;
  }
  .desk-w-70{
    width: 70% !important;
  }
  .desk-w-80{
    width: 80% !important;
  }
  .desk-w-90{
    width: 90% !important;
  }
  .desk-w-100{
    width: 100% !important;
  }

  .deskscrollNotes{
    max-height: 6.8em;
    overflow-y: auto;
  }

  .listScroll{
    max-height: 50vh;
    overflow-y: auto;
  }

  body {
    overflow-y:scroll;
    font-family:"Pt Sans", helvetica, sans-serif;
  }

  .add-vehicle label{
    background: #eee;
    width:100%;
    color:#333;
    padding-top:2px;
    padding-bottom:2px;
    padding-left:6px;
    margin-bottom:0px;
    font-size: 1.2em;
    font-weight:bold;
  }

    .edit-vehicle label{
    background: #eee;
    width:100%;
    color:#333;
    padding-top:2px;
    padding-bottom:2px;
    padding-left:6px;
    margin-bottom:0px;
    font-size: 1.2em;
    font-weight:bold;
  }


  .add-vehicle placeholder{
    font-style:italics;
  }

  .add-vehicle .form-group{
    margin-bottom:10px;
  }


  .max30{
    width:200px;
  }

}

.btn-sq-lg {
  width: 150px !important;
  height: 150px !important;
}

.btn-sq {
  width: 100px !important;
  height: 100px !important;
  font-size: 10px;
}

.btn-sq-sm {
  width: 50px !important;
  height: 50px !important;
  font-size: 10px;
}

.btn-sq-xs {
  width: 25px !important;
  height: 25px !important;
  padding:2px;
}


.shadow_1 {
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
}
.shadow_2 {
  -webkit-box-shadow: 0 1px 2px #777;
}
.shadow_3 {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}
.shadow_4 {
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
}
.shadow_5 {
  box-shadow: 0 0 1px rgba(34, 25, 25, 0.4);
}
.shadow_6 {
  box-shadow: 0 1px #FFFFFF inset, 0 1px 3px rgba(34, 25, 25, 0.4);
}
.shadow_7 {
  box-shadow: 0 4px 2px -3px;
}
.shadow_8 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}
.shadow_9 {
  border-bottom: 0 none;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.46);
}
.shadow_10 {
  -webkit-box-shadow: 0 7px 4px #777;
  -moz-box-shadow: 0 7px 4px #777;
  box-shadow: 0 7px 4px #777;
}
.shadow_11 {
  -webkit-box-shadow: 0 3px 2px #777;
  -moz-box-shadow: 0 3px 2px #777;
  box-shadow: 0 3px 2px #777;
}
.shadow_12 {
  box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #B3B3B3;
}
.shadow_13 {
  -webkit-box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
  box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
}
.shadow_14 {
  -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.shadow_15 {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
  border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
  border-style: none solid solid none;
  border-width: medium 1px 1px medium;
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
  -moz-border-bottom-colors: none;
  -moz-border-image: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
}
.shadow_16 {
  box-shadow: 0 1px 1px 0 #C7C7C7 inset;
  background: none repeat scroll 0 0 #E9E9E9;
}
