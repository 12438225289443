.vehicle-display-info-header{
  display: grid;
  grid-template-columns: 4fr 5fr;
  border-bottom: 1px solid rgba(0,0,0,.2);
  font-size: .75em;
  .ReactVehicleHeadTabSmall{
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  .ReactVehicleHeadTab{
    padding: 4px;
  }
  .vehicle-display-info-header-status{
    // display: flex;
    // align-items:center;
    position: relative;
    border-radius: 2px;

    border-right: 1px solid rgba(0,0,0,.2);
    border-radius: 0;
    .d-flex{
      align-items: center;
    }

  }
  .vehicle-display-info-header-status-indicator{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    // position: absolute;
    // right: 5px;
    // top: 5px;
  }
  .vehicle-display-info-header-tab-history, .tabRow{
    box-shadow: 0 0 1px 1px rgba(0,0,0,.2);
    margin: 0 10px;
    background-color: white;
    color: black !important;
    &:hover{
      background-color: rgb(230,230,230);
    }
    .ReactVehicleHeadTabSmall{
      padding: 5px 10px;
      &:hover{
        background-color: inherit;
        background: inherit;
        color: inherit;
      }
    }
  }
}
