.site-header-container{
  width: 100%;
  height: 90px;
  box-sizing: border-box;
  padding: 0 20px;
}
.site-header{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
}
.site-header-left, .site-header-center,.site-header-right{
  height: 90px;
}
.site-header-left{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 1.05em;
  box-sizing: border-box;
}
.site-header-center{
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px 0;
}
.site-header-logo{
  height: 100%;
  box-sizing: border-box;
  padding: 20px 0;
  text-align: left;
  img{
    height: 100%;
  }

}

.site-header-reporting{
  font-size: .75em;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 1px 1px rgba(0,0,0,.2);
  .time-dashboard-table{
    table{

    }
    thead{
      text-transform: capitalize;
      color: white;
      background-color: #2c55ff;
      color:white;
    }
  }
}
.site-header-right{
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px 0;
}
.site-header-nav-links{
  padding: 0;
  margin: 0;
  li{
    display: inline-block;
    text-align: center;
    margin: 0 10px;
    a{
      box-sizing: border-box;
      padding: 5px;
      border-radius: 5px;
      font-size: .85em;

    }
    &:hover{
      a{
        background-color: #2c55ff;
        color:white;
      }
    }
  }
}
