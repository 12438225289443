/*Avenir Next*/

@font-face {
  font-family: 'Avenir Next';
  font-weight: 400;
  font-style: normal;
  src: url('./avenirnext/avenirnext-regular.woff2') format('woff2'),
      url('./avenirnext/avenirnext-regular.woff') format('woff');;
}

@font-face {
  font-family: 'Avenir Next';
  font-weight: 500;
  font-style: normal;
  src: url('./avenirnext/avenirnext-medium.woff2') format('woff2'),
      url('./avenirnext/avenirnext-medium.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Next';
  font-weight: 600;
  font-style: normal;
  src: url('./avenirnext/avenirnext-demibold.woff2') format('woff2'),
      url('./avenirnext/avenirnext-demibold.woff') format('woff');
}

@font-face {
  font-family: 'Avenir Next';
  font-weight: 700;
  font-style: normal;
  src: url('./avenirnext/avenirnext-bold.woff2') format('woff2'),
      url('./avenirnext/avenirnext-bold.woff') format('woff');
}
