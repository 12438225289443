.ReactVehicle{
  backgrouind-image: linear-gradient(to right, #434343 0%, black 100%);
}

.ReactVehicleHead{
  background: #000;
}

label.clearfix {
  display: inline;
}

.ReactVehicleHeadTab {
  color: white;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
  padding:4px;
  border-right:thin solid white;
  cursor: pointer;
  // box-shadow: inset 0 2px 0 rgba(255, 255, 255, 0.2), inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 -1px 1px rgba(0, 0, 0, 0.1), 1px 1px 2px rgba(0, 0, 0, 0.2);
  background: linear-gradient(#343a40, #343a40);
  transition: all 0.1s ease-out;
  color: white;
  border: none;
  vertical-align: middle;
}

.ReactVehicleHeadTabSmall {
  color: white;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
  border-right:thin solid white;
  cursor: pointer;
  box-shadow: inset 0 2px 0 rgba(255, 255, 255, 0.2), inset 0 0 0 1px rgba(0, 0, 0, 0.2), inset 0 -1px 1px rgba(0, 0, 0, 0.1), 1px 1px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease-out;
  color: rgb(60,60,60);
  border: none;
  vertical-align: middle;
  transition: all 0.2s ease-in-out;
}

.ReactVehicleHeadTab:hover, .ReactVehicleHeadTabSmall:hover {
background: rgb(60,60,60);
 background-blend-mode: multiply;
 color: white;
}

.carvana-color{
  background-color:#00acd8;
  color: #FFF;
}

.iconBase{
      cursor: pointer;
    display: inline-block;
    vertical-align: top;
    padding-top: 1px;
    margin: 0 auto;
    margin-top: 5px;
    border-radius: 4px;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}


.rounded-top {
    border-top-left-radius: 0.35rem !important;
    border-top-right-radius: 0.35rem !important;
}

.vehicleInfo{
  padding-top:3px;
}

.bg-vehicle{
  background: #fff;
}

.ReactVehicleBody {
// background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);
padding: 2px;
text-align:center;
}

.ReactVehicleBody label{
  font-weight:bold;
  text-transform: capitalize;
  text-align:center;
}

.ReactNotesBox{
  transition: all 0.1s ease;
}
.ReactNoteTime {
  font-style: italic;
  font-size: 9px;
  font-weight: bold;
  color: #666;
}

.ReactNoteName {
  margin-right: 5px;
  font-style: italic;
  font-weight: bold;
  font-size: 11px;
}

.ReactNotesBox:hover {
    max-height: 100%;
    heigth: auto;
}

.deskscrollNotes{
  max-height: 6.8em;
  overflow-y: scroll;
}
