@import '../../style//colors';

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  z-index: 99;
}
.loader-outer {
  position: absolute;
  top: calc(50% - 50px);
  width: 100%;
}
.loader {
  margin: 0 auto;
  width: 100px;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.circular {
  animation: rotate 0.75s linear infinite;
  height: 100%;
  transform-origin: center center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 3s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: $recon-blue;
  }
  40% {
    stroke: $recon-blue;
  }
  66% {
    stroke: $ipacket-green;
  }
  80%,
  90% {
    stroke: $ipacket-green;
  }
}

.refresh {
  margin: 0 auto;
  text-align: center;
  span {
    cursor: pointer;
    color: $recon-blue;
    &:hover {
      text-decoration: underline;
    }
  }
  svg {
    margin: 25px;
    width: 50px;
    height: 50px;
  }
}
