#page-vehicles-wrapper{
  .loading-skeleton {
    display: flex;
    align-items: center;
    max-width: 88%;
    margin-left: 2%;
    svg {
      &:first-child {
        min-width: 200px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  #page-vehicles-wrapper{
    .loading-skeleton {
      svg {
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
}