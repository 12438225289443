.wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}

.dd-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  cursor: default;
  position: relative;
  background-color: #fff;
  padding-right:10px;
}
.dd-header span {
  margin-right: 20px;
}
.dd-header-title {
  font-weight: 300;
  margin: 2px 10px;
  margin-right: 5px;
}
.angle-down {
  color: #000;
  margin-right: 20px;
}
.reportbox .dd-list {
  z-index: 10;
  position: absolute;
  border: 1px solid #dfdfdf;
  border-top: none;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 5px -1px #e8e8e8;
          box-shadow: 0 2px 5px -1px #e8e8e8;
  padding: 15px 0;
  padding-bottom:30px;
  margin-bottom:60px;
  -webkit-overflow-scrolling: touch;
}
.dd-list {
  z-index: 10;
  position: absolute;
  border: 1px solid #dfdfdf;
  border-top: none;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 5px -1px #e8e8e8;
          box-shadow: 0 2px 5px -1px #e8e8e8;
  padding: 15px 0;
  padding-bottom:30px;
  margin-bottom:60px;
  max-width: 400px;
  -webkit-overflow-scrolling: touch;
}
.dd-list.scroll {
  overflow-y: scroll;
  max-height: 600px;
  -webkit-overflow-scrolling: touch;
}
.dd-list-item {
  width: 100%;
  font-size: 1.0rem;
  padding: 8px 10px;
  line-height: 1.6rem;
  cursor: default;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: thin solid beige;
  text-align:left;
}
.dd-list-item.selected {
  color: #fff;
  background-color: #3cf;
}
.dd-list-item:hover {
  color: #fff;
  background-color: #3cf;
}
.dd-wrapper-single {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  width: 265px;
}
.dd-wrapper-single .dd-header {
  border: 1px solid #ccc;
}
.dd-wrapper-single .dd-header .dd-header-name {
  font-weight: 400;
}
.dd-wrapper-single .dd-list {
  border: 1px solid #ccc;
  border-top: none;
}
