.vehicle-display-tab-selection{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 4px;
  .ReactVehicleHeadTabSmall{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}
