 body {
    overflow-x: hidden;
    font-family: 'Avenir Next';
 }

 .navbar {
  line-height: 1.25;
  border-bottom: solid thin #dede;
  font-weight: 400;
 }

 .container-fluid{
	padding-left:0px;
    padding-right:0px;
}

.page-vehicles, #page-vehicles-wrapper {
  padding-bottom: 60px;
}

.search-top {
  min-width: 250px;
}

.add-email-report .control-label {
  background: transparent;
  color: white;
}

.main, .dealerpage {
	min-height: 100vh;
	background-color:#FFF;
}

.table th, .table td {
  padding: 0.25rem;
}
.uvi-form .table th, .uvi-form .table td {
  background-color: transparent;
}

.overlay {
	min-height: 100vh;
	background-color:#000;
}

.buttonbar {
  font-size: .85em;
  line-height: .95;
}
.input-group > .form-control.search-input{
}

.edit-vehicle-column-page .control-label{
  background-color: #e9ecef;
  text-color:#000;
  border-bottom: 1px black solid;
  padding:10px;
  width: 100%;
}

.customModal {
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}

.customCard {
  width: 80%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  background-color: #FFFFF7;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}

.customHeader {
  color: white;
  padding-left: 0;
  padding-right: 0;
  display: block;
  float: right;
}

.headerWrapper {
  background-color: grey;
  color: white;
}

.closeButton {
  background-color: red;
  color: white;
  float: right;
  border-radius: 1em;
}

.footerWrapper {
  text-align: center;
  background-color: dark;
}

.edit-vehicle-column-page p{
  margin-left:20px;
}

.uci-table{
  font-size: 80%;
}
.uci-form .row{
  margin-left: 0;
  margin-right:0;
}

.uvi-form table, .uvi-form th, .uvi-form td{
  border: 1px solid black;
}

.strikeout{
  background-image: url('https://cdn.ipacketrecon.com/recon_images/strikethrough.png');
  background-repeat: repeat-x;
  background-position: center;
}

.uci-section{
  width: 100%;
}

.btn-primary, .btn-warning, .btn-success, .btn-danger {
}

.btn-primary{
  
}
.left-login{
    background-color: #fff;
    border-radius: 0;
    max-width: 480px;
    z-index: 2;
    height: 100vh;
}
.right-login{
}

.login-page{
    align-items: center;
    align-items: stretch;
    background: url(https://cdn.ipacketrecon.com/new_react/login_background.jpg) 50%;
    background-size: cover;
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    position: relative;
}


.loginBox {
	background-color:#000;
	padding-top: 10px;
	padding-bottom: 10px;
}

input:-webkit-autofill{
-webkit-box-shadow: 0 0 0 30px white inset;
		hover,focus,active {
    transition: background-color 5000s ease-in-out 0s;
    }
}

.side-box a.list-group-item:hover, .side-box a.list-group-item.active{
  color:#FFF;
  background-color:#007bff;
  cursor: pointer;
}

.content-box{
  padding-bottom: 5vh;
}

.side-box{
  padding-bottom: 5vh;
}

.tab-box {
  display:none;
}

.tab-box.active {
  display:block;
}

.vehicle-tab-box {
  display:none;
}

.vehicle-tab-box.active {
  display:flex;
}

.bg-light .nav-item, .bg-light .nav-link{
  cursor: pointer;
}

.tab-box .card-body, .tab-box .card-footer, .dashBox .card-body, .dashBox .card-footer{
}

.card-summary{
  padding: .25rem;
  background: 'black';
}

ul {
	list-style: none;
}

.headericon{
  cursor: pointer;
}

.reportHeaderDash {
  width: 100px;
  font-size: 12px;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
