.vehicle-badge-counts{
  width: 30px;
  margin-right: 2px;
}
.vehicle-badge-counts-hidden{
  visibility: hidden;
}
.sidebar-nav-item{
  font-size: .77em;
}
.sidebar-nav-item-info{
  display: grid;
  grid-template-columns: 1fr auto;
}
.sidebar-nav-item-info-counts{
  display: flex;
  align-items: center;
}
