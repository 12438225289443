.add-work-item-modal{
  h1{
    font-size: 1.35em
  }
  h2{
    font-size: 1.25em;
    margin-top: 10px;
    margin-bottom: 0;
  }
}
