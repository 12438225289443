.vehicle-display-image-container{
  width: 100%;
  overflow: hidden;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0,0,0,0.2);
}
.vehicle-display-image{
  height: 100%;
}
.vehicle-display-image-placeholder-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.vehicle-display-image-placeholder{
  width: 50px;
}
