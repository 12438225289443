.vehicle-display-tab-view {
  font-size: 0.75em;
  .tab-view-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    grid-auto-rows: auto;
    gap: 8px;
    justify-items: stretch;
    align-items: stretch;
    width: 100%;
    .tab-view-item {
      width: 190px;
      border: 1px solid lightgrey;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 4px;
      min-height: 100px;
      label {
        margin-bottom: 6px !important;
      }
      input[type="checkbox"] {
        transform: scale(1.2);
      }
      .switch {
        font-size: 0.8rem;
        max-width: 98px;
        .text-center {
          overflow-wrap: break-word;
        }
      }
      table {
        margin-bottom: 0;
        thead {
          th {
            width: 50%;
          }
        }
        tbody {
          border-style: hidden;
        }
      }
    }
  }
}
