.dealership-search-page{
  animation: 1000ms fadeIn;
}
.dealership-search-page-header{
  border-bottom: 1px solid rgba(0,0,0,.2);
}
.dealership-search-page-body{
  max-width: 1800px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 10px 10px 10px;
  box-sizing: border-box;
  background-color: white;
  min-height: 100vh;
  border-radius: 2px;
}
.dealership-search-page-header-section{
  display: flex;
  align-items: center;
}
.dealership-search-page-header-section-right{
  justify-content: flex-end;
  .btn{
    margin-right: 20px;
  }
}
.dealership-search-card{
  border-radius: 5px;
  overflow: hidden;
  .list-group{
    border-radius: 0;
    border-width: 0;
    outline-width: 0;
    .list-group-item{
      border-width: 0;
      border-top-width: 1px;
    }
  }
  .dealership-search-card-header{
    border-radius: 0;
  }
  .dealership-search-card-body{}
  .dealership-search-card-footer{
    padding: 0;
    .btn-primary{
      border-radius: 0;
    }
  }
}
