.vehicle-display-container{
  width: 100%;
  padding: 5px 10px;
  box-sizing: border-box;
  border: 1px solid rgba(0,0,0,.2);
  background-color: white;
  width: 99%;
  margin: 10px auto;
}
.vehicle-display{
  width: 100%;

}
.vehicle-display-main{
  display: grid;
  grid-template-columns: auto 1fr;
}
.vehicle-display-main-left{
  width: 325px;
  margin-right: 5px;
}
.vehicle-display-expanded-container{
  width: 100%;
}
.vehicle-display-expanded{
  width: 100%;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 4px 5px;
}
.checkboxes-tab{
  .checkboxes-tab-label{
    font-size: 1.25em;
  }
}
